<template>
  <div :class="`flex flex-col h-[100dvh] bg-white transition-all ease-in-out duration-500 border-0 border-r border-solid border-steam ${isExpanded ? 'w-[270px]' : smallScreen ? 'bg-transparent border-none h-[73px] w-[calc(100vw-32px)] bottom-[30px] fixed z-[99992] right-[14px] left-[14px]' : 'w-[82px]'}`">

    <!-- Logo -->
    <router-link tag="div" :class="`px-[16px] gap-[13px] mt-[32px] mb-[24px] cursor-pointer flex items-center ${!isExpanded && 'mx-auto'} ${smallScreen && 'hidden'}`" :to="`/${$route.params.lang}/merchant/dashboard`">
      <img src="../../assets/images/logo/logo.svg" :class="isExpanded ? 'w-[56px] h-[32px]' : 'w-[41px] h-[23px]'"/>
      <img v-if="isExpanded" class="overflow-hidden" src="../../assets/images/logo/shipblu-logo.svg" height="28"/>
    </router-link>
    <!-- /Logo -->
    
    <!--Start Nav Menu Items -->
    <div :class="smallScreen ? `${openNavbarMobile ? 'fixed p-1 visible animate-fade-in-up' : 'invisible animate-fade-up-down'} bg-white left-[17px] right-[17px] top-[29px] rounded-[12px] bottom-[120px] border-solid border border-gainsboro` : 'flex flex-col px-[16px] gap-[10px] overflow-auto w-full h-full'">
      <!-- Toggle Buttons -->
      <button v-if="!smallScreen" class="border-0 bg-primary rounded-full w-[20px] h-[20px] absolute lg:flex hidden justify-center items-center top-[37px] transition-all ease-in-out duration-500" :class="isExpanded ? 'left-[261px]' : 'left-[75px]'" @click="toggleSidebar()">
        <shipblu-icon iconLibrary="material" iconClass="text-base text-white cursor-pointer" :iconName="$route.params.lang === 'en' ? isExpanded ? 'chevron_left' :'chevron_right' : isExpanded ? 'chevron_right' :'chevron_left'"></shipblu-icon>
      </button>
      <!-- /Toggle Buttons --> 

      <div class="flex flex-col h-full">
        <div :class="`${smallScreen && 'overflow-y-auto h-full px-4 pt-[12px]'}`">
          <div v-for="(route, index) in smallScreen ? updateNavMenuItems : navMenuItems" :key="index">

            <!-- Item Without Header -->
            <router-link @click.native="openNavbarMobile = false" exactActiveClass="bg-primary text-[#fff]" v-if="!route.header" :to="`/${$route.params.lang}${route.url}`" :class="`flex rounded-[5px] items-center gap-[10px] p-[10px] text-young-night transition-all ease-in-out duration-600 ${!isExpanded && !smallScreen && 'justify-center'} ${smallScreen && 'justify-start rounded-[10px] p-[12px]'} ${$route.path === `/${$route.params.lang}${route.url}` ? 'hover:bg-[#1c5bfe] hover:text-white' : 'hover:bg-carte-blanche'}`">
              <shipblu-icon v-if="isExpanded" :iconLibrary="route.iconLibrary" :iconName="route.icon" :iconClass="`${$route.path === `/${$route.params.lang}${route.url}` ? 'text-white' : 'text-bauhaus'} ${route.iconLibrary === 'feather' ? 'w-[20px] h-[20px]': 'text-[20px]'}`"></shipblu-icon>
              <vx-tooltip v-else :position="`${$route.params.lang ==='en' ? 'right' :'left'}`" color="dark" :text="`${$t(route.i18n)}`" class="flex items-center">
                <shipblu-icon :iconLibrary="route.iconLibrary" :iconName="route.icon" :iconClass="`${$route.path === `/${$route.params.lang}${route.url}` ? 'text-white' : 'text-bauhaus'} ${route.iconLibrary === 'feather' ? 'w-[20px] h-[20px]': 'text-[20px]'}`"></shipblu-icon>
              </vx-tooltip>
              <p v-if="isExpanded || smallScreen" class="text-[16px]">{{ $t(route.i18n) }}</p>
            </router-link>

            <!-- Item With Header -->
            <div v-else>
              <div :class="`text-young-night header w-full flex items-center gap-[10px] ${!isExpanded && 'justify-center'} ${smallScreen ? 'rounded-[10px] p-[12px]' : 'py-[11px] px-[10px]'}`">
                <p v-if="isExpanded || smallScreen" class="text-[14px] text-industrial-revolution whitespace-nowrap">{{ $t( route.i18n )}}</p>
                <p v-if="isExpanded || smallScreen" class="w-full h-[1px] bg-cold-morning"></p>
                <p v-if="!isExpanded && !smallScreen" class="text-[14px] text-industrial-revolution whitespace-nowrap">{{ route.shortHeader}}</p>
              </div>
              <div v-for="(item, index) in route.items" :key="index">
                <!-- Sidebar link if the item has a URL -->
                <router-link @click.native="openNavbarMobile = false" v-if="item.url" active-class="bg-primary text-[#fff]" :to="`/${$route.params.lang}${item.url}`" :class="`flex rounded-[5px] items-center ${isExpanded ? 'justify-between' : 'justify-center'} ${smallScreen ? 'justify-between rounded-[10px] p-[12px]': 'justify-center'} p-[10px] ${$route.path === `/${$route.params.lang}${item.url}` ? 'hover:bg-[#1c5bfe] hover:text-white' : 'hover:bg-carte-blanche'} transition-all ease-in-out duration-400 text-young-night`">
                  <div class="flex items-center gap-[10px] relative">
                    <shipblu-icon v-if="isExpanded || smallScreen" :iconLibrary="item.iconLibrary" :iconName="item.icon" :iconClass="`${$route.path === `/${$route.params.lang}${item.url}` ? 'text-white' : 'text-bauhaus'} ${item.iconLibrary === 'feather' ? 'w-[20px] h-[20px]': 'text-[20px]'}`"></shipblu-icon>
                    <vx-tooltip v-else :position="`${$route.params.lang ==='en' ? 'right' :'left'}`" color="dark" :text="`${$t(item.i18n)}`" class="flex items-center">
                      <shipblu-icon :iconLibrary="item.iconLibrary" :iconName="item.icon" :iconClass="`${$route.path === `/${$route.params.lang}${item.url}` ? 'text-white' : 'text-bauhaus'} ${item.iconLibrary === 'feather' ? 'w-[20px] h-[20px]': 'text-[20px]'}`"></shipblu-icon>
                    </vx-tooltip>
                    <p v-if="isExpanded || smallScreen" class="text-[16px]">{{ $t(item.i18n) }}</p>
                    <!-- <span :class="`bg-fluorescent-red text-[9px] text-white rounded-full w-[15px] h-[15px] flex items-center justify-center absolute top-1/2 ${isExpanded ? 'left-[calc(100%+6px)]' : 'left-full'}`" v-if="item.id === 3" style="transform: translateY(-50%);">2</span> -->
                  </div>
                </router-link>

                <!-- Non-clickable item if item has no URL -->
                <div v-else :class="`${isExpanded ? 'justify-between' : 'justify-center'} ${ !smallScreen && isTabActive(item.submenu && item.submenu, item.name) === item.name && 'bg-primary text-[#fff]'} w-full sm:flex block rounded-[5px] cursor-pointer items-center p-[10px] ${!smallScreen && isOpenSubmenu(item.id) ? 'bg-primary text-[#fff] rounded-[5px]' : 'text-young-night'} ${!smallScreen && isTabActive(item.submenu && item.submenu, item.name) !== item.name && 'hover:bg-carte-blanche hover:text-young-night'}`" @mouseenter="!smallScreen && toggleSubmenu(item.id)" @mouseleave="!smallScreen && toggleSubmenu(item.id)" @click="smallScreen && toggleSubmenu(item.id)">
                  <div :id="`parent-${item.id}`" :class="`flex items-center gap-[10px] ${smallScreen && isOpenSubmenu(item.id) && 'mb-[16px]'}`">
                    <shipblu-icon :iconLibrary="item.iconLibrary" :iconName="item.icon" :iconClass="`${ !smallScreen && isTabActive(item.submenu && item.submenu, item.name) === item.name ? 'text-white' : 'text-bauhaus'} ${item.iconLibrary === 'feather' ? 'w-[20px] h-[20px]': 'text-[20px]'}`"></shipblu-icon>
                    <p v-if="isExpanded || smallScreen" class="text-[16px]">{{ $t(item.i18n) }}</p>
                    <span v-if="smallScreen" class="material-symbols-rounded cursor-pointer text-base text-bauhaus ml-auto">{{isOpenSubmenu(item.id) ? 'keyboard_arrow_down' : $route.params.lang === 'ar' ? 'chevron_left' : 'chevron_right' }}</span>
                  </div>
                  <shipblu-icon v-if="item.submenu && isExpanded" iconLibrary="material" :iconName="$route.params.lang === 'ar' ? 'chevron_left' : 'chevron_right'" :iconClass="`cursor-pointer text-base ${!smallScreen && isOpenSubmenu(item.id) || isTabActive(item.submenu && item.submenu, item.name) === item.name ? '' : 'text-bauhaus'}`"></shipblu-icon>
                  
                  <!-- Sub Item With Dropdown -->
                  <div :id="`submeun-${item.id}`" :style="!smallScreen && (submenuPositions[`submenu-${item.id}`])" :class="`${smallScreen ? `${isOpenSubmenu(item.id && item.id) ? 'block' : 'hidden'}` : 'fixed bg-transparent'} ${ !smallScreen && (isOpenSubmenu(item.id) ? 'opacity-100 z-[99994]' :'opacity-0 z-[-1]')}`">
                    <div :class="`container-submenu ${smallScreen ? 'flex flex-col gap-[16px]' : `pl-[35px] fixed z-[999] min-w-[160px]`}`">
                      <router-link @click.native="openNavbarMobile = false"  :exactActiveClass="`${smallScreen ? 'text-[#1c5bfe]' : 'bg-[#1c5bfe] text-[#fff]'}`" v-for="(subRoute, index) in item.submenu" :key="index" :to="`/${$route.params.lang}${subRoute.url}${subRoute.query ? subRoute.query : ''}`" :class="`cursor-pointer flex whitespace-nowrap px-[12px] gap-[11px] py-[8px] items-center bg-white text-young-night border-1.5 border-t-0 border-solid border-[#DDDDDD] ${!smallScreen ? `min-h-[39px] ${index === 0 && 'rounded-[5px] rounded-b-none border-t-1.5' } ${index === item.submenu.length -1 && 'rounded-[5px] rounded-t-none' } ${item.submenu.length === 1 && 'rounded-t-[5px] rounded-b-[5px]'} ${$route.path === `/${$route.params.lang}${subRoute.url}` ? 'hover:bg-[#1c5bfe] hover:text-white' : 'hover:bg-carte-blanche'} border-collapse`: 'border-none px-[0px] py-[0px] hover:text-marine-blue'}`">
                        <shipblu-icon v-if="!smallScreen" :iconLibrary="subRoute.iconLibrary" :iconName="subRoute.icon" :iconClass="`${`/${$route.params.lang}${subRoute.url}` === $route.path ? 'text-white' : 'text-bauhaus'} w-[16px] h-[16px] text-[16px]`"></shipblu-icon>
                        <p class="text-[14px]">{{ $t(subRoute.i18n) }}</p>
                      </router-link>
                    </div>      
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Personal Data in Small Screen -->
          <div :class="`${smallScreen ? 'flex flex-col justify-between p-[12px]' : 'hidden'}`">
            <div class="flex items-center gap-[12px]">
              <p class="text-[14px] text-industrial-revolution whitespace-nowrap">{{ $t('Profile') }}</p>
              <p class="w-full h-[1px] bg-cold-morning"></p>
            </div>
            <div id="parent-profileSm" @click="toggleSubmenu('profileSm')" :class="`flex items-center justify-between mt-[33px] w-full cursor-pointer ${isOpenSubmenu('profileSm') ? 'mt-[33px]' : 'my-[33px]' }`">
              <p class="flex items-center gap-[14px]">
                <shipblu-icon iconLibrary="feather" iconName="UserIcon" iconClass="w-[21px] h-[21px] text-young-night"></shipblu-icon>
                <span class="text-[16px] text-young-night">{{$t(userInfo.name)}}</span>
              </p>
              <shipblu-icon iconLibrary="material" :iconName="!isOpenSubmenu('profileSm') ? ($route.params.lang === 'en' ? 'chevron_right' : 'chevron_left') : 'keyboard_arrow_down'" iconClass="cursor-pointer text-bauhaus text-lg"></shipblu-icon>
            </div>
            <div id="submeun-profileSm" :class="`${isOpenSubmenu('profileSm') ? 'grid items-center gap-[23px] mb-[28px] mt-[16px]' : 'hidden'}`">
              <router-link active-class="text-primary" @click.native="openNavbarMobile = false" :to="`/${this.$route.params.lang}/merchant/account-settings/general`" class="flex items-center gap-[4px] cursor-pointer group">
                <span :class="`text-[14px] ${$route.path === `/${this.$route.params.lang}/merchant/account-settings/general` ? 'text-primary' :'text-young-night hover:text-marine-blue' }`">{{ $t('Profile & Settings')  }}</span>
              </router-link>
              <p @click="openBetaVersion()" class="flex items-center gap-[4px] cursor-pointer">
                <span class="text-young-night hover:text-marine-blue text-[14px]">{{  $t(releaseVersion.includes('beta') ? 'Stable Release' : 'Test Version')  }}</span>
              </p>
              <p @click="logout()" class="flex items-center gap-[4px] cursor-pointer group">
                <span class="text-young-night text-[14px] hover:text-marine-blue">{{$t('Logout')}}</span>
              </p>
            </div>
          
          </div>
        </div>

        <!-- Footer Navbar Moblie -->
        <p v-if="smallScreen" class="flex items-center justify-between px-[26.5px] pb-3 flex-row border-solid border border-cold-morning border-r-0 border-l-0 border-b-0 pt-[18px]">
          <span class="text-young-night text-[12px] capitalize whitespace-nowrap">{{ releaseVersion }}</span>
          <i18n :isExpanded="isExpanded" :smallScreen="smallScreen" />
        </p>
      </div>
    </div>
    <!--End Nav Menu Items -->

    <!-- Personal Data in Large Screen -->
    <div v-if="!smallScreen" class="px-[16px] pb-[23px] pt-3 flex flex-col gap-[6px] cursor-pointer">
      <div id="parent-profileLg" class="p-[10px] flex items-center justify-center h-[71px]" :class="isExpanded && 'border border-cold-morning border-solid rounded-[6px] justify-between'" @mouseenter="toggleSubmenu('profileLg', 'bottom')" @mouseleave="toggleSubmenu('profileLg')">
        <div class="flex items-center gap-[9px]">
          <p v-if="userInfo.name" class="text-primary text-[15px] p-[12px] rounded-full bg-[#EBF0FF] border-[#DBE4FF] border border-solid uppercase">{{ userInfo.name.split(' ')[0].charAt(0) + userInfo.name.split(' ')[1].charAt(0) }}</p>
          <span v-if="isExpanded" class="text-young-night">{{ userInfo.name }}</span>
        </div>
        <shipblu-icon v-if="isExpanded" iconLibrary="material" :iconName="$route.params.lang === 'en' ? 'chevron_right' : 'chevron_left'" iconClass="text-base text-bauhaus cursor-pointer"></shipblu-icon>
        <div id="submeun-profileLg" :style="submenuPositions[`submenu-profileLg`]" :class="`fixed bg-white min-w-[180px] ${isOpenSubmenu('profileLg') ? 'opacity-100 z-[99994]': 'opacity-0 z-[-1]'}`">
          <div :class="`container-submenu ${smallScreen ? '' : `pl-[35px] fixed z-[999] min-w-[180px] max-w-[250px]`}`">
            <router-link tag="div" active-class="bg-[#1c5bfe] text-[#fff]" :to="`/${this.$route.params.lang}/merchant/account-settings/general`" :class="`group py-3 text-young-night text-[14px] flex items-center gap-[8px] px-[10px] rounded-t-[5px] border-1.5 border-solid border-[#DDDDDD] border-b-0  bg-white ${$route.path === `/${this.$route.params.lang}/merchant/account-settings/general` ? 'hover:bg-[#1c5bfe] hover:text-white' : 'hover:bg-carte-blanche'}`">
              <shipblu-icon iconLibrary="phosphor" iconName="gear" :iconClass="`text-[21px] ${$route.path === `/${this.$route.params.lang}/merchant/account-settings/general` ? 'text-white' :'text-bauhaus'}`"></shipblu-icon>
              <span>{{ $t('Profile & Settings') }}</span>
            </router-link>
            <div @click="openBetaVersion()" :class="`py-3 bg-white border-1.5 border-solid border-t-0 border-b-0 border-[#DDDDDD] text-young-night text-[14px] flex items-center gap-[8px] px-[10px] hover:bg-carte-blanche`">
              <img src="../../assets/images/logo/logo-dark.svg" alt="logo-dark">
              <span>{{ $t(releaseVersion.includes('beta') ? 'Stable Release' : 'Test Version') }}</span>
            </div>
            <div @click="logout()" :class="`py-3 bg-white text-young-night text-[14px] flex border-1.5 items-center gap-[8px] p-[10px] border-t-[1px] border-solid border-cold-morning px-[10px] hover:bg-carte-blanche rounded-[5px] rounded-t-none`">
              <shipblu-icon iconLibrary="material" iconName="logout" iconClass="text-[21px] group-hover:text-white"></shipblu-icon>
              <span>{{ $t('Logout') }}</span>
            </div>
          </div>
        </div>
      </div>
      <p class="flex" :class="isExpanded ? 'justify-between cursor-default flex-row items-center' : 'justify-center flex-col gap-[20px]'">
        <span :class="`text-sliver text-[10px] capitalize whitespace-nowrap text-center ${!isExpanded && 'order-2'}`">{{ releaseVersion }}</span>
        <i18n :isExpanded="isExpanded" :class="`${!isExpanded && 'order-1'}`" />
      </p>
    </div>

    <!-- Orders Menu Small Screen -->
    <div :class="`h-[100dvh] w-[100dvw] fixed z-[999] inset-0 ${showOrders ? 'visible animate-fade-in-up' : 'hidden'}`">
      <div :class="`${smallScreen ? 'absolute p-[27px] flex flex-col items-left justify-end gap-[12px] flex-wrap bottom-[112px] w-[100vw]' : 'hidden'} ${showOrders ? 'visible animate-fade-in-up' : 'hidden'}`">
        <div v-for="order in orderTypes" :key="order.index">
          <div @click="pushRouteNavbarMenu(order.url)" class="flex items-center gap-[10px] px-[10px] py-[6px] cursor-pointer">
            <div class="h-[53px] w-[53px] rounded-full bg-primary flex items-center justify-center">
              <shipblu-icon :iconLibrary="order.iconLibrary" :iconName="order.iconName" :iconClass="`${order.iconLibrary === 'feather' ? 'w-[18px] h-[18px]' : 'text-[18px] '} text-white`"></shipblu-icon>
            </div>
            <p class="text-young-night text-[14px]">{{ order.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Background Blur in Small Screen -->
    <div v-if="!($route.name === 'merchant-create-order' || $route.name === 'merchant-complete-profile')" :class="`bg-blur-default ${showOrders || openNavbarMobile ? 'bg-blur-full' : ''}`"></div>

    <!-- Start Navbar Small Screen -->
    <div v-if="!($route.name === 'merchant-create-order' || $route.name === 'merchant-complete-profile')" :class="`${smallScreen ? 'py-[12px] px-[28px] flex items-center w-full h-full absolute z-[999] bg-white left-0 border-solid border border-gainsboro shadow-[0_5px_10px_rgba(0,0,0,0.5)] rounded-[12px] justify-between': 'hidden'}`">
      <shipblu-icon iconLibrary="phosphor" iconName="list" iconClass="cursor-pointer text-[30px] text-bauhaus" @click="handlerNavbarMobile()"></shipblu-icon>
      <div class="relative flex items-center">
        <!-- <span :class="`bg-fluorescent-red text-[9px] text-white rounded-full w-[15px] h-[15px] flex items-center justify-center absolute -top-2 left-[calc(100%-8px)]`">2</span> -->
        <shipblu-icon iconLibrary="material" iconName="ads_click" iconClass="cursor-pointer w-full text-bauhaus text-[28px]" @click="pushRouteNavbarMenu('/merchant/action-center')"></shipblu-icon>
      </div>
      <shipblu-icon iconLibrary="material" iconName="add" iconClass="cursor-pointer text-white text-[20px] p-[15px] rounded-full bg-primary" :style="`transition: all 200ms linear; ${showOrders && 'transform: rotate(45deg);'}`" @click="openOrderMenu()"></shipblu-icon>
      <shipblu-icon iconLibrary="feather" iconName="BellIcon" iconClass="w-[28px] h-[28px] text-bauhaus cursor-pointer" :badge="unreadNotification > 0 ? unreadNotification : ''" @click="pushRouteNavbarMenu('/merchant/notifications')"></shipblu-icon>
      <shipblu-icon iconLibrary="phosphor" iconName="house" iconClass="cursor-pointer text-primary text-[30px]" @click="pushRouteNavbarMenu('/merchant/dashboard')"></shipblu-icon>
    </div>
    <!-- End Navbar Small Screen -->
  </div>
</template>

<script>
import I18n   from '../components/navbar/components/newI18n.vue'
import AXIOS  from '@/http/axios/index.js'

export default {
  name: 'Sidebar',
  props: ['navMenuItems'],
  data () {
    return {
      isExpanded: true,
      smallScreen: false,
      showOrders: false,
      openNavbarMobile: false,
      openSubmenus: [],
      submenuPositions: {},
      windowWidth: window.innerWidth,
      orderTypes: [
        {
          id:'1',
          name: 'Delivery',
          iconName: 'arrow-right',
          iconLibrary: 'phosphor',
          url: '/merchant/create-order?tab=delivery-orders'
        },
        {
          id:'2',
          name: 'Return',
          iconName: 'arrow-left',
          iconLibrary: 'phosphor',
          url: '/merchant/create-order?tab=returns'
        },
        {
          id:'3',
          name: 'Exchange',
          iconName: 'RepeatIcon',
          iconLibrary: 'feather',
          url: '/merchant/create-order?tab=exchanges'
        },
        {
          id:'4',
          name: 'Cash Collection',
          iconName: 'money',
          iconLibrary: 'phosphor',
          url: '/merchant/create-order?tab=cash-collections'
        },
        {
          id:'5',
          name: 'Multiple Orders',
          iconName: 'upload-simple',
          iconLibrary: 'phosphor',
          url: '/merchant/orders/upload-order'
        }
      ],
      userInfo: {},
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      unreadNotification: 0,
      notifications: [],
      unread: null,
      offset: ''
    }
  },
  computed: {
    updateNavMenuItems () {
      const itemsToRemove = ['Dashboard', 'Notifications', 'Action Center']
      return this.navMenuItems
        .map(item => ({
          ...item,
          items: item.items ? item.items.filter(subItem => !itemsToRemove.includes(subItem.name)) : []
        })).filter(item => !itemsToRemove.includes(item.name) || item.items.length)
    }
  },
  watch:{
    windowWidth:{
      handler (val) {
        val <= 1024 ? this.isExpanded = false : this.isExpanded = true
        this.smallScreen = val <= 575
        this.openNavbarMobile = val >= 768 && false
      },
      immediate: true
    }
  },
  methods: {
    updateWidth () {
      this.windowWidth = window.innerWidth
    },
    toggleSidebar () {
      this.isExpanded = !this.isExpanded
      this.$emit('toggleSidebar', this.isExpanded)
    },
    isOpenSubmenu (index) {
      return this.openSubmenus.includes(index)
    },
    toggleSubmenu (index, changePosition) {
      if (this.openSubmenus.includes(index)) {
        this.openSubmenus = this.openSubmenus.filter((i) => i !== index)
        setTimeout(() => {
          this.submenuPositions[`submenu-${index}`] = { 
            zIndex: -1
          }
        }, 1)
      } else {
        this.openSubmenus.push(index)
        this.calculateSubmenuPosition(index, changePosition) 
      }
    },
    calculateSubmenuPosition (index, position) {
      // (Left or Right) Calculate total space for submenu position = full width of sidebar - 35px (padding left in div container-submenu)
      // (Top) Calculate the top position of the submenu as follows:
      // parentBounding.bottom - (parentBounding.height + 10)
      // - parentBounding.bottom: The bottom position of the parent element
      // - parentBounding.height: The height of the parent element
      // - 10px: Additional spacing (such as padding) to adjust the submenu position
      
      const parentElement = document.getElementById(`parent-${index}`)
      const submenuElement = document.getElementById(`submeun-${index}`)
      if (parentElement && submenuElement) {
        const parentBounding = parentElement.getBoundingClientRect()
        this.submenuPositions[`submenu-${index}`] = {
          top: position && position === 'bottom' ? `${(parentBounding.bottom - (parentBounding.height * 2) + 10)}px` : `${parentBounding.bottom - (parentBounding.height + 10)}px`,
          [this.$route.params.lang === 'ar' ? 'right' : 'left']: this.isExpanded ? '245px' : '57px',
          zIndex: 1
        }
      } 
    },
    isTabActive (list, name) {
      const checkActive = list.filter(element => {
        return element.slug === this.$route.name
      })
      return checkActive.length > 0 && name
    },
    openOrderMenu () {
      this.showOrders = !this.showOrders
      this.openNavbarMobile = false
    },
    handlerNavbarMobile () {
      this.openNavbarMobile = !this.openNavbarMobile
      this.showOrders = false
    },
    openBetaVersion () {
      if (this.releaseVersion.includes('beta')) {
        window.open('https://app.shipblu.com/', '_blank')
      } else {
        window.open('https://app.beta.shipblu.com/', '_blank')
      }
    },
    logout () {
      this.$auth.logOut()
      this.$acl.change('guest')
      localStorage.clear()
    },
    loadAllNotifications (type) {
      AXIOS.get(`${process.env.VUE_APP_API_URL}/api/v2/buzzer/in-app/?last_evaluated_key=${this.offset}&limit=15`, {headers: this.headers}, {
      })
        .then((response) => {
          if (type) {
            this.notifications = response.data.results
          } else {
            this.notifications = [...this.notifications, ...response.data.results]
          }
          this.unread = this.notifications.map(element => element.status === 'unread').filter(item => item === true)
          localStorage.setItem('notifications', this.unread.length)
          this.offset = response.data.next
        })
        
    },
    pushRouteNavbarMenu (url) {
      this.$router.push(`/${this.$route.params.lang}${url}`).catch(() => {})
      this.openNavbarMobile = false
      this.showOrders = false
    }
  },
  created () {
    this.loadAllNotifications('notScroll')
    setInterval(function () {
      this.unreadNotification = localStorage.getItem('notifications')
    }.bind(this), 100)
    this.userInfo = localStorage.getItem('idTokenPayload') ? JSON.parse(localStorage.getItem('idTokenPayload')) : {}
    window.addEventListener('resize', this.updateWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.updateWidth)
  },
  components: {
    I18n
  }
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #fff !important; 
  border-radius: 6px !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E5E5E5 !important; 
  border-radius: 6px !important;
}
.bg-blur-default{
  position: fixed;
  top: 92%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.bg-blur-full {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.feather-icon-badge {
  background: #FF5151 !important;
  font-size: 9px !important;
}
</style>